import Vue from "vue";
import VueRouter from "vue-router";
import { getUserId, getUserType } from "@/utils/store";
import { isMobile } from "../utils/common";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }
  return originalPush.call(this, location).catch((err) => err);
};
const commonRoutes = [
  {
    path: "/landingPage",
    name: "LandingPage",
    component: () => import("../views/landingPage/index.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/login",
    name: "InputEmail",
    component: () => import("../views/login/index.vue"),
    meta: {
      requiresAuth: false,
      title: "Please input email",
    },
  },
  {
    path: "/login/pwd",
    name: "InputPwd",
    component: () => import("../views/login/InputPwd.vue"),
    meta: {
      requiresAuth: false,
      title: "Please input pincode",
    },
  },
];

let routes;
let user_type = getUserType();
if (isMobile()) {
  routes = [
    {
      path: "/",
      redirect: "/mobile/home",
    },
    {
      path: "/mobile/home",
      name: "Index",
      component: () => import("../views/mobile/home"),
      meta: {
        requiresAuth: true,
        title: "Refff",
      },
    },
    {
      path: "/mobile/home/view-refff",
      name: "Index",
      component: () => import("../views/mobile/cardNote"),
      meta: {
        requiresAuth: true,
        title: "Refff",
      },
    },
    ...commonRoutes,
  ];
} else {
  routes = [
    {
      path: "/",
      redirect: "/home",
      meta: {
        requiresAuth: true,
        title: "Home",
      },
    },
    {
      path: "/home",
      name: "Home",
      redirect: "/home/my-refffs",
      component: () => import("../views/Home.vue"),
      children: [
        {
          path: "my-refffs",
          component: () => import("../views/myCardNotes"),
          meta: {
            requiresAuth: true,
            title: "My Refffs",
          },
        },
        {
          path: "view-refff",
          component: () => import("../views/cardNote"),
          meta: {
            requiresAuth: true,
            title: "View Refff",
          },
        },
        {
          path: "messages",
          component: () => import("../views/messages/index.vue"),
          meta: {
            requiresAuth: true,
            title: "Messages",
          },
        },
        {
          path: "add",
          component: () => import("../views/addNote"),
          meta: {
            requiresAuth: true,
            title: "New Refff",
          },
        },
        {
          path: "my-archive",
          component: () => import("../views/archive.vue"),
          meta: {
            requiresAuth: true,
            title: "My Archive",
          },
        },
        {
          path: "archive-detail",
          component: () => import("../views/archive-detail.vue"),
          meta: {
            requiresAuth: true,
            title: "Archive Detail",
          },
        },
        {
          path: "my-agent-service",
          component: () => import("../views/agent.vue"),
          meta: {
            requiresAuth: true,
            title: "Agent Service",
          },
        },
        {
          path: "my-agent-list",
          component: () => import("../views/agentList.vue"),
          meta: {
            requiresAuth: true,
            title: "Agent List",
          },
        },
      ],
    },
    {
      path: "/export",
      name: "Index",
      component: () => import("../views/export/index.vue"),
      meta: {
        requiresAuth: true,
        title: "Refff",
      },
    },
    ...commonRoutes,
    {
      path: "/article-detail",
      component: () => import("../views/article.vue"),
      meta: {
        requiresAuth: false,
        title: "Article Detail",
      },
    },
  ];
}

const router = new VueRouter({
  linkActiveClass: "active",
  routes,
});
router.beforeEach((to, from, next) => {
  let userId = getUserId();
  let user_type = getUserType();
  if (to.meta.requiresAuth && !userId) {
    next({
      path: "/login",
    });
  } else {
    next();
  }
});
export default router;
