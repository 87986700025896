<template>
  <div id="app">
    <!-- <header class="header" v-if="isShowHeader">
      <div class="view-root">
        <div class="header-container">
          <div style="flex: 1;display: flex;align-items: center;">
            <img
              src="./assets/logo_refff.png"
              style="
                width: 100px;
                height: 28px;
                object-fit: cover;
                margin-left: 24px;
              "
            />
            <p class="user-style" style="margin-left: 15px;font-weight: bold;font-size: 18px;">Beta</p>
          </div>
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
            "
          >
            <el-dropdown style="margin-right: 32px" v-if="this.$UserData.user_email">
              <p class="user-style">Hi, {{ this.$UserData.user_email }}</p>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <div @click="gotoMessages" style="color: #ffc569">Feedback</div>
                </el-dropdown-item>
                <el-dropdown-item>
                  <div @click="logout" style="color: #ffc569">Logout</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

        
          </div>
        </div>
      </div>
    </header> -->
    <el-container style="margin: 0px; padding: 0px">
      <el-main style="margin: 0px; padding: 0px">
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { clearUser, getUserEmail } from "./utils/store";
import "./assets/common/common.css";

export default {
  name: "App",
  data() {
    return {};
  },
  mounted() {
    this.$UserData.user_email = getUserEmail();
  },

  computed: {
    isShowHeader() {
      let show = true;
      let path = this.$route.path;
      if (path.indexOf("export") >= 0) {
        show = false;
      }
      if (!this.$isMobile) {
        if (this.$route.path.search("login") >= 0) {
          show = false;
        }
      }
      return show;
    },
  },
  methods: {
    gotoMessages() {
      this.$router.push("/home/messages");
    },
    logout() {
      this.$alert("Are you sure you want to log out？", "Notice", {
        confirmButtonText: "Confirm",
        callback: (action) => {
          if (action == "confirm") {
            clearUser();
            location.reload();
          }
        },
      });
    },
  },
};
</script>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}
</style>
<style scoped lang="less">
.header-container {
  margin: 0 auto;
  height: 6vh;
  background-image: url("./assets/header_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.user-style {
  font-size: 18px;
  font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
  font-weight: 500;
  color: #ffffff;
}
</style>
