import Cookies from 'js-cookie'

const USER_ID = 'user_id'
const USER_EMAIL = 'user_email'
const USER_NAME = 'user_name'
const USER_TYPE = 'user_type'
const PUBLISHER_USER_EMAIL = 'publisher_user_email'

export function setUserEmail(email) {
    Cookies.set(USER_EMAIL, email)
}

export function getUserEmail() {
    return Cookies.get(USER_EMAIL)
}

export function setPublisherEmail(email) {
    Cookies.set(PUBLISHER_USER_EMAIL, email)
}

export function getPublisherEmail() {
    return Cookies.get(PUBLISHER_USER_EMAIL)
}

export function setUserName(user_name) {
    Cookies.set(USER_NAME, user_name)
}

export function getUserName() {
    return Cookies.get(USER_NAME)
}

export function setUserId(user_id) {
    Cookies.set(USER_ID, user_id)
}

export function getUserId() {
    return Cookies.get(USER_ID)
}

export function setUserType(user_type) {
    Cookies.set(USER_TYPE, user_type)
}

export function getUserType() {
    return Cookies.get(USER_TYPE)
}

export function clearUser() {
    Cookies.remove(USER_EMAIL)
    Cookies.remove(USER_ID)
    Cookies.remove(USER_NAME)
    Cookies.remove(PUBLISHER_USER_EMAIL)
    Cookies.remove(USER_TYPE)
}
