import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "normalize.css";
import router from "./router";
import UserData from "./utils/UserData";
import { isMobile } from "./utils/common";
import dayjs from "dayjs";

import "tailwindcss/tailwind.css";

import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

Vue.prototype.$UserData = UserData;
Vue.prototype.$dayjs = dayjs;
// let isMobileDevice = isMobile()
Vue.prototype.$isMobile = isMobile(); //isMobileDevice
Vue.config.productionTip = false;
Vue.use(ElementUI);
new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");